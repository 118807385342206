<template>
  <div
    class="wrap"
    :class="{ active, 'ar-wrap': $lang === 'ar' }"
  >
    <div class="info">
      <h2 class="title">{{ $langs.aboutUs.title1 }}</h2>
      <div class="desc">
        <p>{{ $langs.aboutUs.desc1 }}</p>
      </div>
      <h2 class="title">{{ $langs.aboutUs.title2 }}</h2>
      <div class="desc">
        <p>{{ $langs.aboutUs.desc2[0] }}</p>
        <p>{{ $langs.aboutUs.desc2[1] }}</p>
        <p>{{ $langs.aboutUs.desc2[2] }}</p>
        <p>{{ $langs.aboutUs.desc2[3] }}</p>
      </div>
      <a
        href="#"
        class="link"
        @click.prevent="$emit('toServices')"
      >{{ $langs.aboutUs.servicesList }}</a>
    </div>
    <div class="img-wrap">
      <img
        v-for="item in 3"
        :key="item"
        class="img"
        :class="{ visible: visibleBg && item === (imgIndex + 1) }"
        :src="require(`@/assets/img/about_us/bg_${item}.jpg`)"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',

  data() {
    return {
      active: false,
      imgIndex: 0,
      visibleBg: false,
    };
  },

  watch: {
    active() {
      this.bgAnimation(true);
    },
  },

  methods: {
    bgAnimation(flag = false) {
      this.visibleBg = false;
      setTimeout(() => {
        this.imgIndex = (this.imgIndex + 1) % 3;
        this.visibleBg = true;
      }, flag ? 0 : 500);
      setTimeout(() => this.bgAnimation(), 5000);
    },
  },
};
</script>

<style lang="sass" scoped>
h2
  margin: 0 0 20px
.wrap
  min-height: 1000px
  display: flex
  justify-content: center
  align-items: center
  padding: 130px 140px 40px
  position: relative
  &.active
    .info
      opacity: 1
.info
  margin: 0 120px
  opacity: 0
  transition: opacity .7s
.title
  color: #A3A6A8
.desc
  margin: 0 0 50px
  p
    font-size: 16px
    margin: 0
    & + p
      margin: 16px 0 0
.link
  text-transform: uppercase
  color: #C3996C
  text-decoration: underline
  font-family: "Stone Sans Bold"
  letter-spacing: 2px
.img-wrap
  z-index: -1
  position: absolute
  top: 0
  width: 100%
  height: 100%
  opacity: .2
  align-items: center
  overflow: hidden
.img
  width: 100%
  opacity: 0
  transition: opacity .5s
  position: absolute
  top: 0
  left: 0
  &.visible
    opacity: 1

.ar-wrap
  .desc p,
  .link
      font-size: 19px

@media screen and (max-width: 1700px)
  .info
    margin: 0
@media screen and (max-width: 1500px)
  .img
    width: auto
    height: 100%
@media screen and (max-width: 1279px)
  .wrap
    padding: 130px 30px 40px
@media screen and (max-width: 900px)
  .img
    left: -50%
@media screen and (max-width: 700px)
  .wrap
    padding: 130px 30px 0
  .img
    display: none
@media screen and (max-width: 600px)
  .wrap
    padding: 130px 15px 0
</style>
