<template>
<div
  class="statistics"
  :class="{ active, 'ar-statistics': $lang === 'ar' }"
>
  <div
    v-for="item in projects"
    :key="item.name"
    class="item-wrap"
  >
    <div class="item">
      <p class="counter">{{ item.value }}</p>
      <p class="entity">{{ item.name }}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Statistics',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    projects: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="sass" scoped>
.statistics
  position: absolute
  width: 400px
  height: 400px
  display: flex
  flex-wrap: wrap
  &::before,
  &::after
    content: ''
    position: absolute
  &::before
    top: 200px
    left: 100px
    right: 100px
    border-top: 1px solid #C3996C
  &::after
    top: 100px
    left: 200px
    bottom: 100px
    border-left: 1px solid #C3996C
  &.active
    .item-wrap
      opacity: 1
      top: 0
.item-wrap
  width: 50%
  display: flex
  flex-direction: column
  align-items: flex-start
  opacity: 0
  position: relative
  top: 30px
  &:nth-child(3),
  &:nth-child(4)
    justify-content: end
  &:nth-child(2),
  &:nth-child(4)
    align-items: flex-end
  &:nth-child(1)
    transition: top .5s, opacity .5s
  &:nth-child(2)
    transition: top .6s, opacity .6s
  &:nth-child(3)
    transition: top .7s, opacity .7s
  &:nth-child(4)
    transition: top .8s, opacity .8s
.counter
  margin: 0
  font-size: 80px
  line-height: 80px
  font-family: Arial
  font-weight: 800
.entity
  margin: 0
  text-align: center
  color: #A3A6A8
  font-size: 16px
  padding-top: 8px

.ar-statistics
  .entity
    font-size: 19px

@media screen and (max-width: 1000px)
  .counter
    font-size: 50px
  .statistics
    width: 200px
    height: 250px
    &::before
      top: 125px
      left: 0
      right: 0
    &::after
      top: 25px
      bottom: 25px
      left: 100px
</style>
