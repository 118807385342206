<template>
  <div
    v-if="showPreview"
    class="preview"
  >
    <video
      ref="video"
      autoplay
      muted
      class="video"
      :class="{ minimize: minimizeVideo }"
    >
      <source
        :src="require('@/assets/video/preview.mp4')"
        type="video/mp4"
      >
    </video>
    <span
      v-show="!videoLoaded"
      class="loading"
    >Loading...</span>
  </div>
</template>

<script>
export default {
  name: 'Preview',

  data() {
    return {
      showPreview: true,
      minimizeVideo: false,
      bodyClassList: document.body.classList,
      videoLoaded: false,
    };
  },

  mounted() {
    if (!['maybe', 'probably'].includes(document.createElement('video').canPlayType('video/mp4'))) {
      this.showPreview = false;
      this.$emit('ready');

      return;
    }

    this.bodyClassList.add('hide');
    this.$refs.video.addEventListener('ended', this.videoEnded);
    this.$refs.video.onloadeddata = () => (this.videoLoaded = true);
  },

  unmounted() {
    this.$refs.video.removeEventListener('ended', this.videoEnded);
  },

  methods: {
    videoEnded() {
      window.scrollTo(0, 0);
      this.minimizeVideo = true;
      setTimeout(() => {
        this.$emit('ready');
        this.showPreview = false;
        this.bodyClassList.remove('hide');
      }, 500);
    },
  },
};
</script>

<style lang="sass" scoped>
.preview
  background: #000
  height: 100vh
  width: 100%
  display: flex
  align-items: center
  position: fixed
  justify-content: center
  z-index: 2
  overflow: hidden
.video
  width: 100%
  max-width: 1920px
  outline: none
  transition: width .5s, top .5s
  position: absolute
  margin-bottom: -80px
  &.minimize
    width: 360px
    top: -8px
.loading
  color: #5F646A

@media screen and (max-width: 1023px)
  .video
    &.minimize
      top: -53px
@media screen and (max-width: 767px)
  .video
    &.minimize
      top: -44px
      width: 300px
</style>
