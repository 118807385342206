<template>
  <div :class="{ 'ar-form': $lang === 'ar' }">
    <form id="contact-form">
    <input
      v-model.trim="letter.name"
      type="text"
      :placeholder="$langs.contactUs.form.name"
      maxlength="255"
    />
    <input
      v-model.trim="letter.email"
      type="text"
      :placeholder="$langs.contactUs.form.email"
      maxlength="255"
    />
    <div class="message">
      <input
        v-model.trim="letter.subject"
        type="text"
        :placeholder="$langs.contactUs.form.subject"
        maxlength="255"
      />
      <textarea
        v-model.trim="letter.message"
        :placeholder="$langs.contactUs.form.message"
        maxlength="1000"
      />
    </div>

    <button
      class="button"
      type="button"
      :disabled="enableSubmitButton"
      @click="sendLetter"
    >{{ $langs.contactUs.form.submit }}</button>
  </form>
  </div>
</template>

<script>
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';

export default {
  name: 'ContactForm',

  data() {
    return {
      letter: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
    };
  },

  computed: {
    enableSubmitButton() {
      return !(this.letter.name || this.letter.email || this.letter.subject || this.letter.message);
    },
  },

  methods: {
    sendLetter() {
      if (!this.letter.name || !this.letter.email || !this.letter.subject || !this.letter.message) {
        Swal.fire({
          icon: 'error',
          title: 'Incomplete Information',
          text: 'Please fill the form completely.',
        });
        return;
      } else if (!this.validateEmail(this.letter.email)) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Email',
          text: 'Your email is not valid.',
        });
        return;
      }

      // eslint-disable-next-line
      // email validation
      this.processEmail(this.letter);
      // this.letter.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },
    async processEmail() {
      // get api data for render
      let res = await fetch('https://one-fm.com/api/method/one_fm.www.index.send_contact_email', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.letter),
      });
      res = await res.json();
      if (res.message) {
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: 'Message sent successfully, we will be in touch with you as soon as possible.',
        });
        // clear form
        document.querySelector('#contact-form').reset();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops! Something went wrong',
          text: 'Our engineers are working on it. Please send a direct email via info@one-fm.com or give us a call on +965 1866336. Thank you for your understanding.',
        });
      };
    },
    validateEmail(email) {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.message
  margin-top: 25px
.button
  background: none
  color: #C3996C
  border: 0
  padding: 0
  cursor: pointer
  letter-spacing: 1px
  transition: color .25s
  padding: 2px 5px
  margin: 14px -5px 0
  letter-spacing: 2px
  outline: none
  &:hover
    text-decoration: underline
  &:disabled
    color: #5F646A
    pointer-events: none

.ar-form
  input, textarea
    font-size: 17px
  .button
    font-size: 19px
</style>
