<template>
  <video
    v-if="showVideo"
    autoplay
    loop
    muted
    class="video"
  >
    <source
      :src="require('@/assets/video/partners.mp4')"
      type="video/mp4"
    >
  </video>
</template>

<script>
export default {
  name: 'ClientsVideo',

  data() {
    return {
      showVideo: false,
    };
  },

  mounted() {
    this.showVideo = true;
  },
};
</script>

<style lang="sass" scoped>
.video
  height: 100%
</style>
