<template>
  <div class="block-wrap">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlockWrap',
};
</script>

<style lang="sass" scoped>
.block-wrap
  max-width: 1920px
  overflow: hidden
  margin: 0 auto
  width: 100%
  position: relative
</style>
