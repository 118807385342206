<template>
  <div class="gallery-wrap">
    <agile>
      <div
        v-for="x in 1"
        :key="x"
        class="gallery-item"
      >
        <div
          v-for="item in partners"
          :key="item"
          class="partner"
        >
            <img
              :src="`${item}`"
              :alt="`Partner ${item}`"
            >
        </div>
      </div>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';

export default {
  name: 'PartnersGallery',
  props: {
    partners: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      partnerList: [],
    };
  },
  components: {
    agile: VueAgile,
  },
  watch: {
    partners(partners) {
      const partnerList = [];
      const partnerItem = [];
      partners.forEach((item, index) => {
        if (partnerItem.length === 6) {
          partnerList.push(partnerItem);
          partnerItem.length = 0;
        }
        partnerItem.push(item);
      });
      partnerList.push(partnerItem);
    },
  },
};
</script>

<style lang="sass" scoped>
.gallery-wrap
  margin-top: -80px
  max-width: 400px
  overflow: hidden
  &::v-deep
    .agile__nav-button
      display: none
    .agile__actions
      justify-content: center
      padding-top: 40px
    .agile__dot
      margin: 5px
      button
        width: 10px
        height: 10px
        padding: 0
        border-radius: 5px
        border: 0
        background: #777
        transition: background .25s
    .agile__dot--current
      button
        background: #fff
.gallery-item
  user-select: none
  display: flex
  flex-wrap: wrap
.partner
  min-width: 33.33%
  text-align: center
  margin-top: 80px

@media screen and (max-width: 1000px)
  .gallery-wrap
    max-width: 300px
@media screen and (max-width: 767px)
  .gallery-item
    margin-top: 40px
  .gallery-wrap
    margin: -40px 0 70px
    max-width: 100%
</style>
