<template>
  <header :class="{ 'ar-header': $lang === 'ar' }">
    <div class="header-wrap">
      <a
        href="/"
        class="logo"
      >
        <img
          :src="require(`@/assets/img/logo.svg`)"
          alt="OneFm"
        >
      </a>
      <div
        class="menu"
        :class="{ active: showMenu }"
      >
        <nav class="nav">
          <li
            v-for="(item, index) in nav"
            :key="index"
            class="nav-item"
          >
            <a
              class="nav-link"
              :class="{ active: activeBlock === item.blockName, lang: item.changeLang }"
              @click="item.changeLang ? changeLang() : clickLink(item)"
            >{{ item.title }}</a>
          </li>
        </nav>
      </div>
      <Sandwich
        :show-menu="showMenu"
        @click="this.showMenu = !this.showMenu"
      />
    </div>
  </header>
</template>

<script>
import Sandwich from './Sandwich';

export default {
  name: 'PageHeader',

  components: {
    Sandwich,
  },

  props: {
    blocks: {
      type: Object,
      required: true,
    },

    activeBlock: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showMenu: false,
      nav: [
        {
          title: this.$langs.headerNav.home,
          blockName: this.blocks.home,
        },
        {
          title: this.$langs.headerNav.about,
          blockName: this.blocks.about,
        },
        {
          title: this.$langs.headerNav.services,
          blockName: this.blocks.services,
        },
        {
          title: this.$langs.headerNav.clients,
          blockName: this.blocks.clients,
        },
        {
          title: this.$langs.headerNav.jobs,
          blockName: this.blocks.jobs,
        },
        {
          title: this.$langs.headerNav.contact,
          blockName: this.blocks.contact,
        },
        {
          title: this.$langs.headerNav.login,
          blockName: this.blocks.login,
        },
        {
          title: this.$langs.headerNav.lang,
          changeLang: true,
        },
      ],
    };
  },

  mounted() {
    document.addEventListener('click', this.closeOpenMenu);
  },

  unmounted() {
    document.removeEventListener('click', this.closeOpenMenu);
  },

  methods: {
    clickLink(item) {
      if (item.blockName === 'login') {
        window.location.href = 'https://one-fm.com/login';
      } else if (item.blockName === 'jobs') {
        window.location.href = 'https://one-fm.com/careers';
      } else {
        this.$emit('scrollTo', item.blockName);
      }
    },

    changeLang() {
      const newLang = this.$lang === 'en' ? 'ar' : 'en';

      window.localStorage.setItem('lang', newLang);
      window.location.reload();
    },

    closeOpenMenu(e) {
      const target = e.target;

      if (window.innerWidth > 900 || !this.showMenu || target.closest('.sandwich')) return;

      if (
        target.classList.contains('menu') ||
        target.classList.contains('nav-link') ||
        target.closest('.logo')
      ) {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 1
  pointer-events: none
.header-wrap
  max-width: 1920px
  margin: 0 auto
  padding: 60px 70px 0
  display: flex
  align-items: center
  min-height: 130px
  justify-content: flex-end
  position: relative
.logo
  position: absolute
  top: 60px
  left: 70px
  transition: left .5s, margin .5s, right .5s
  z-index: 999
.logo,
.menu
  pointer-events: all
.menu
  transition: opacity .25s
.nav
  display: flex
  list-style: none
  transition: opacity .5s, margin .25s
.nav-item
  padding: 0 25px
  &:first-child
    padding-left: 0
  &:last-child
    padding-right: 0
.nav-link
  padding: 5px
  text-shadow: 0 0 5px #000
  font-size: 18px
  cursor: pointer
  position: relative
  display: inline-block
  &::before
    content: ''
    border-bottom: 1px solid #C3996C
    position: absolute
    left: 5px
    right: 5px
    bottom: 5px
    opacity: 0
    transition: opacity .25s
  &.active
    pointer-events: none
    &::before
      opacity: 1

.ar-header
  .logo
    left: auto
    right: 70px
  .nav-link:not(.lang)
    font-size: 21px
@media screen and (max-width: 1279px)
  .nav-item
    padding: 0 15px
@media screen and (max-width: 1023px)
  .header-wrap
    padding: 15px 30px 0
    min-height: 85px
  .logo
    top: 15px
    left: 30px
  .nav-item
    padding: 0 10px
  .ar-header
    .logo
      right: 30px
@media screen and (max-width: 900px)
  .menu
    position: fixed
    left: 0
    width: 100%
    top: 0
    bottom: 0
    background: rgba(0, 0, 0, .4)
    &:not(.active)
      opacity: 0
      pointer-events: none
      .nav
        margin: 0 0 0 -200px
  .nav
    flex-direction: column
    width: 250px
    background: #181818
    height: 100%
    box-shadow: 5px 0 15px -5px rgba(255, 255, 255, .1)
    padding-top: 110px
  .nav-item
    padding: 0 15px !important
  .ar-header
    .menu
      &:not(.active)
        .nav
          margin: 0 -200px 0 0
@media screen and (max-width: 767px)
  .logo
    left: 15px
    img
      width: 130px
  .ar-header
    .logo
      right: 15px
</style>
