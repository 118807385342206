<template>
  <ul
    class="link-list"
    :class="{ 'ar-link-list': $lang === 'ar' }"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      class="list-item"
    >
      <a
        v-if="item.blockName"
        class="link"
        href="#"
        @click.prevent="$emit('scrollTo', item.blockName)"
      >{{ item.name }}</a>
      <a
        v-else
        class="link"
        :href="item.link"
      >{{ item.name }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FooterLinkList',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.link-list
  margin: 0
  padding: 0
  list-style: none
.link
  letter-spacing: 2px
  font-size: 14px
.list-item + .list-item
  margin: 14px 0 0

.ar-link-list
  .link
    font-size: 17px

@media screen and (max-width: 767px)
  .list-item + .list-item
    margin: 7px 0 0
</style>
