<template>
  <div
    class="home-info"
    :class="{ active, 'ar-home-info': $lang === 'ar' }"
  >
    <h1
      class="home-title"
      v-html="$langs.homeInfo.title"
    />
    <p class="desc">{{ $langs.homeInfo.desc }}</p>
    <div class="link-wrap">
      <a
        href="#"
        class="link"
        @click.prevent="$emit('toAboutUs')"
      >{{ $langs.homeInfo.aboutUs }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeInfo',

  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.home-info
  width: 800px
  position: absolute
  margin: 0 -328px 0 0
  padding-top: 100px
  &.active
    .home-title,
    .desc,
    .link-wrap
      margin-top: 0
      opacity: 1
.home-title,
.desc,
.link-wrap
  opacity: 0
  margin-top: -30px
.home-title
  color: #A3A6A8
  transition: margin-top .6s, opacity .5s
  &::v-deep
    span
      color: #fff
.desc
  font-size: 17px
  max-width: 470px
  line-height: 24px
  padding-top: 50px
  transition: margin-top .8s, opacity 1s
.link-wrap
  text-align: right
  padding: 30px 80px 0 0
  transition: margin-top 1s, opacity 1.5s
.link
  text-transform: uppercase
  color: #C3996C
  text-decoration: underline
  font-family: 'Stone Sans Bold'
  letter-spacing: 2px
  &:hover
    text-decoration: none

.ar-home-info
  margin: 0 0 0 -328px
  .desc
    font-size: 20px
  .link-wrap
    padding: 30px 0 0 80px
    text-align: left
  .link
    font-size: 19px
@media screen and (max-width: 1279px)
  .home-info,
  .ar-home-info
    margin: 0
@media screen and (max-width: 900px)
  .home-info
    left: 0
    width: auto
    padding: 0 30px
  .home-title
    font-size: 50px
@media screen and (max-width: 768px)
  .home-title
    font-size: 40px
@media screen and (max-width: 600px)
  .home-info
    padding: 0 15px
  .home-title
    font-size: 30px
  .desc
    padding-top: 20px
    margin: 0
@media screen and (max-width: 500px)
  .link-wrap,
  .ar-home-info .link-wrap
    padding: 30px 0 0 0
</style>
