<template>
  <footer :class="{ 'ar-footer': $lang === 'ar' }">
    <div class="contact-block">
      <div class="logo">
        <img
          :src="require(`@/assets/img/footer_logo.svg`)"
          alt="OneFm"
        >
      </div>
      <p><a href="mailto:info@one-fm.com">info@one-fm.com</a></p>
      <p><a dir="ltr" href="tel:+9651866336">+(965) 186 6336</a></p>
      <p>{{ $langs.footer.legal }} <span id="year-footer"></span></p>
    </div>
    <FooterLinkList
      :items="footerNav"
      class="link-list"
      @scrollTo="$emit('scrollTo', $event)"
    />
    <FooterLinkList
      :items="$langs.footer.list1"
      class="link-list"
    />
    <FooterLinkList
      :items="$langs.footer.list2"
      class="link-list"
    />
    <SocialMedia class="social" />
  </footer>
</template>

<script>
import FooterLinkList from './FooterLinkList';
import SocialMedia from './SocialMedia';

export default {
  name: 'PageFooter',

  components: {
    FooterLinkList,
    SocialMedia,
  },

  props: {
    blocks: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      footerNav: [
        {
          name: this.$langs.footer.footerNav.about,
          blockName: this.blocks.about,
        },
        {
          name: this.$langs.footer.footerNav.services,
          blockName: this.blocks.services,
        },
        {
          name: this.$langs.footer.footerNav.clients,
          blockName: this.blocks.clients,
        },
        {
          name: this.$langs.footer.footerNav.jobs,
          blockName: this.blocks.jobs,
        },
        {
          name: this.$langs.footer.footerNav.contact,
          blockName: this.blocks.contact,
        },
      ],
    };
  },
  mounted() {
    const todaydate = new Date();
    document.querySelector('#year-footer').innerText = todaydate.getFullYear();
  },
};
</script>

<style lang="sass" scoped>
footer
  margin: 150px 270px 100px 140px
  position: relative
  display: flex
  flex-wrap: wrap
.social
  position: absolute
  bottom: 0
  right: 0
.link-list + .link-list
  margin: 0 0 0 170px
.contact-block
  letter-spacing: 2px
  font-size: 14px
  margin: 0 130px 0 0
  .logo
    margin: 0 0 20px
  p
    margin: 0

.ar-footer
  margin: 150px 140px 100px 270px
  .social
    right: auto
    left: 0
  .link-list + .link-list
    margin: 0 170px 0 0
  .contact-block
    font-size: 17px
    margin: 0 0 0 130px

@media screen and (max-width: 1700px)
  footer,
  .ar-footer
    margin: 150px 140px 100px
@media screen and (max-width: 1600px)
  .contact-block
    margin: 0 100px 0 0
  .link-list + .link-list
    margin: 0 0 0 100px
  .ar-footer
    .contact-block
      margin: 0 0 0 100px
    .link-list + .link-list
      margin: 0 100px 0 0
@media screen and (max-width: 1600px)
  .contact-block
    margin: 0 70px 0 0
  .link-list + .link-list
    margin: 0 0 0 70px
  .ar-footer
    .contact-block
      margin: 0 0 0 70px
    .link-list + .link-list
      margin: 0 70px 0 0
@media screen and (max-width: 1279px)
  footer,
  .ar-footer
    margin: 50px 40px
@media screen and (max-width: 1023px)
  footer,
  .ar-footer
    margin: 50px 30px 20px
    justify-content: space-between
  .social
    position: relative
    flex: 0 0 100%
    justify-content: center
    padding-top: 50px
  .contact-block
    margin: 0
  .link-list + .link-list
    margin: 0
  .ar-footer
    .contact-block
      margin: 0
    .link-list + .link-list
      margin: 0
@media screen and (max-width: 767px)
  .contact-block,
  .link-list
    width: 50%
    margin-top: 20px !important
    text-align: center
@media screen and (max-width: 500px)
  footer,
  .ar-footer
    margin: 50px 15px 20px
  .contact-block,
  .link-list
    text-align: left
  .contact-block,
  .link-list:nth-child(3)
    padding: 0 20px 0 0
  .ar-footer
    .contact-block,
    .link-list:nth-child(3)
      padding: 0 0 0 20px
    .contact-block,
    .link-list
      text-align: right
</style>
