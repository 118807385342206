export default {
  en: {
    headerNav: {
      home: 'Home',
      about: 'About Us',
      services: 'Our Services',
      clients: 'Clients',
      jobs: 'Careers',
      contact: 'Contact Us',
      login: 'Login',
      lang: 'العربية',
    },

    homeInfo: {
      title: 'Leaders in <span>Integrated</span> & <span>Automated</span> Facility Management Services',
      desc: 'ONE FM is a leading provider of Facility Management  and Support services in Kuwait with a range of expertise in retail premises, manufacturing centers, and airports.',
      aboutUs: 'About us',
    },

    aboutUs: {
      title1: 'Why we are ONE',
      desc1: 'We are a client focused company which is deeply rooted in a service mindset. We make sure that we always provide the number one service to our clients. Our in house developed technologies allows us to provide to our clients a service that is unparalleled to in speed of service and cost of service.  By doing so we can support our clients to be the number ONE in their field.',
      title2: 'Years of Experience',
      desc2: [
        'Since our establishment in 2009 we have grown to be the leader in Facility Management in Kuwait. We have developed our internal systems and technologies that allow us to cover all of Kuwait. Serving  a wide range of businesses and organizations in both Government & Private sectors.',
        'Depending on your requirements, if your facility needs any improvements, we make sure to provide you with expert analysis for your business or industry. We focus to provide our services within your budget and recommendations. Regardless of what your industry is in and what you need, we can provide professional services to meet your needs.',
        'ONE Facilities Management is using the latest systems & technologies in implementing the best services in Kuwait by using advanced data science techniques and strong reporting systems that help us and our clients to be aware of all details. ONE Facilities Management provides bespoke services to all the organizations from small, medium and to all types of large scale organizations. Our client retention exceeds the 95% mark and our clients are in the industries of Construction, Retail, Manufacturing, Oil & Gas, Banking, and Transportation. ONE Facilities Managements have developed systems for our clients which allow them to track all the services by detailed reports and assessments to enhance the quality and requirements meeting their needs.',
        'We are using the latest technology & software in managing our clients sites with experienced personnel. We are the best in providing professionally trained personnel in a wide range of services to cater to all different sectors.',
      ],
      servicesList: 'Our services list',
    },

    services: [
      {
        title: 'SECURITY SERVICES',
        desc: 'We are using the latest technology & software in securing our sites with experienced security guards and supervisors. We use our own post management and patrolling software to develop detailed reports. We are the best in providing professionally trained security guards to a wide range of industries in different sectors.',
      },
      {
        title: 'HVAC SERVICES',
        desc: 'We provide maintenance services by a wide variety of coverage options to keep your indoor air systems safe and reliable by maintaining Air Condition & HVAC systems. We also have technologies and softwares to manage central plants such as air-cooled and water-cooled chillers with all auxiliary systems and cooling towers. We do our best to give your HVAC systems their most life in the most efficient way.',
      },
      {
        title: 'ELEVATOR GENERATOR SERVICES',
        desc: 'We have decades of experience in the field and successfully provide our Generator Maintenance, Repair, Troubleshooting services from small scale organizations to the large scale organizations in all related sectors in Kuwait.',
      },
      {
        title: 'CLEANING SERVICES',
        desc: 'As a professional cleaning company, we train and certify our staff and show them the correct methods of cleaning. We have all the equipments and professional tools to get your job done. We use our own task management software to define the right frequencies to get your cleaning job done right.',
      },
      {
        title: 'PLUMBING SERVICES',
        desc: 'We have expert technicians who offer a wide range of services for the new installations and complete plumbing repair or troubleshooting services. We make sure that your water supply and sewage pipings are all ways maintained. We also handle fire fighting systems as well.',
      },
      {
        title: 'IT SERVICES',
        desc: 'We using the latest procedures & technologies in implementing the best security services in Kuwait by using advanced proposing techniques and strong reporting systems that help us and our clients to be aware of all details.',
      },
      {
        title: 'ELECTRICAL SERVICES',
        desc: 'We offer a wide range of services includes Electrical Engineering, Wiring, Ground Testing and Electricians to our commercial & industrial customers. We provide expert services to our clients meeting their needs and requirements. We ensure our customers by providing efficient Electrical Maintenance services.',
      },
      {
        title: 'LANDSCAPING SERVICES',
        desc: 'We have specialized engineers and experienced professionals in the field of landscaping who visit your site and inspect the soil, surface, water, and drainage to deliver the landscaping services by meeting your requirements.',
      },
      {
        title: 'FACILITIES MANAGEMENT SERVICES',
        desc: 'We operate on the business functions by helping clients, customers and end users understand the potential impact of their decisions on the provision of space, services, cost, and business risk. We ensure a corporate and cost-effective environment for the organizations to function.',
      },
    ],

    partners: {
      title: 'Our Clients',
      desc: 'Below is are some of our clients. We love to work with them and our goal is to make them happy.',
    },

    statisticsData: [
      {
        counter: '04',
        entity: 'Clients',
      },
      {
        counter: '02',
        entity: 'Projects',
      },
      {
        counter: '01',
        entity: 'Sites',
      },
      {
        counter: '06',
        entity: 'Employers',
      },
    ],

    positions: {
      title: 'Are you a good fit?<br>Check our open positions!',
      positionItems: [
        {
          name: 'Jr. Accountant',
          desc: `We've got a vacancy for you! We're looking for a great accountant to work alongside  our team.`,
          link: '#',
        },
        {
          name: 'Front-End Developer',
          desc: `You're in the right place then! This job opening is for you: we're looking for a great Front-End Developer to work alongside our Design and Development teams.`,
          link: '#',
        },
        {
          name: 'Jr. Accountant',
          desc: `We've got a vacancy for you! We're looking for a great accountant to work alongside  our team.`,
          link: '#',
        },
        {
          name: 'Front-End Developer',
          desc: `You're in the right place then! This job opening is for you: we're looking for a great Front-End Developer to work alongside our Design and Development teams.`,
          link: '#',
        },
      ],
      positionsNote: {
        title: 'Nothing for you here?',
        desc: [
          'View all vacancies.',
          'Say',
        ],
      },
      viewVaccancy: 'view vacancy',
    },

    contactUs: {
      title: 'Or get in touch with us!',
      desc: 'Whether you have a question about features, trials, pricing, or anything else, our team is ready to answer all your questions',
      address: 'Address: Humoud Tower, 80, Kuwait City, Kuwait',
      phone: 'Phone:',
      form: {
        name: 'Your name',
        email: 'Your E-mail',
        subject: 'Message subject',
        message: 'Your message',
        submit: 'Submit',
      },
    },

    footer: {
      footerNav: {
        about: 'About us',
        services: 'Our services',
        clients: 'Clients',
        jobs: 'Jobs',
        contact: 'Contact us',
      },
      list1: [
        {
          name: 'Support',
          link: '#',
        },
        {
          name: 'Press',
          link: '#',
        },
        {
          name: 'Newsletter',
          link: '#',
        },
        {
          name: 'Brand Assets',
          link: '#',
        },
      ],
      list2: [
        {
          name: 'Cookie policy',
          link: '#',
        },
        {
          name: 'Terms & conditions',
          link: '#',
        },
        {
          name: 'Privacy policy',
          link: '#',
        },
        {
          name: 'FAQ',
          link: '#',
        },
      ],
      legal: 'ONE Facilities Management © ',
    },
  },

  // -------------------------------------------------------------------------------

  ar: {
    headerNav: {
      home: 'الصفحة الرئيسية',
      about: 'معلومات عنا',
      services: 'خدماتنا',
      clients: 'عملائنا',
      jobs: 'وظائف',
      contact: 'اتصل بنا',
      login: 'دخول',
      lang: 'English',
    },

    homeInfo: {
      title: 'رواد في خدمات إدارة المرافق المتكاملة والأوتوماتيكية',
      desc: 'ONE FM هي إحدى الشركات الرائدة في تقديم خدمات إدارة المرافق والدعم في الكويت مع مجموعة من الخبرة في منشآت البيع بالتجزئة ومراكز التصنيع والمطارات.',
      aboutUs: 'معلومات عنا',
    },

    aboutUs: {
      title1: 'لماذا نحن الرقم واحد',
      desc1: 'نحن شركة تركز على العملاء و لديها جذور عميقة في الخدمات .نتأكد من أننا نقدم دائمًا الخدمة الافضل لعملائنا. تتيح لنا تقنياتنا المطورة داخليًا أن نقدم لعملائنا خدمة لا مثيل لها من حيث سرعة الخدمات والتكلفة. من خلال القيام بذلك ، يمكننا دعم عملائنا ليكونوا الأوائل في مجالهم.',
      title2: 'سنوات من الخبرة',
      desc2: [
        'منذ تأسيسها في عام 2009 ، تطورنا لتصبح الرواد في إدارة المرافق في الكويت. لقد قمنا بتطوير أنظمتنا وتقنياتها الداخلية التي تسمح لنا بتغطية جميع مناطق الكويت. خدمة مجموعة واسعة من الشركات والمؤسسات في كل من القطاعين الحكومي والخاص.',
        'بناءً على متطلباتك ، إذا كانت منشأتك بحاجة إلى أي تحسينات ، فإننا نتأكد من تزويدك بتحليل خبير لعملك أو صناعتك. نحن نركز على تقديم خدماتنا في حدود ميزانيتك وتوصياتك. بغض النظر عن طبيعة صناعتك أو ما تحتاجه ، يمكننا تقديم خدمات احترافية لتلبية احتياجاتك.',
        'تقدم ون لإدارة المرافق أحدث الأنظمة والتقنيات في تنفيذ أفضل الخدمات في الكويت باستخدام تقنيات علم البيانات المتقدمة وأنظمة إعداد التقارير القوية التي تساعدنا وعملائنا على أن نكون على دراية بكل التفاصيل . نحن نستخدم خدمات مخصصة لجميع المؤسسات من الصغيرة والمتوسطة إلى جميع أنواع المنظمات واسعة النطاق. تتجاوز نسبة احتفاظنا بعملائنا نسبة 95 ٪ ويعمل عملائنا في صناعات البناء والتجزئة والتصنيع والنفط والغاز والمصارف والنقل لا يقتصر على جميع أنواع المنظمات في الكويت. قامت ون لادارة المرافق بتطوير أنظمة لعملائنا تتيح لهم تتبع جميع الخدمات من خلال العديد من التقارير والتقييمات لتحسين الجودة والمتطلبات التي تلبي احتياجاتهم.',
        'نحن نستخدم أحدث التقنيات والبرامج في إدارة مواقع عملائنا مع موظفين ذوي خبرة. نحن الأفضل في توفير موظفين مدربين بشكل احترافي في مجموعة واسعة من الخدمات لتلبية جميع القطاعات المختلفة.',
      ],
      servicesList: 'قائمة خدماتنا',
    },

    services: [
      {
        title: 'خدمات الأمن',
        desc: 'نحن نستخدم أحدث التقنيات والبرامج في تأمين مواقعنا مع حراس الأمن والمشرفين ذوي الخبرة. نحن الأفضل في توفير حراس أمن مدربين بشكل احترافي لمجموعة واسعة من الصناعات في مختلف القطاعات.',
      },
      {
        title: 'خدمات التكييف',
        desc: 'نقدم خدمات الصيانة من خلال مجموعة واسعة من خيارات التغطية للحفاظ على أنظمة الهواء الداخلية الخاصة بك آمنة وموثوقة من خلال الحفاظ على أنظمة التكييف والتدفئة والتهوية وتكييف الهواء لدينا ايضا تقنيات و برامج لإدارة المحطات المركزية مثل المبردات بالهواء و المبرده بالماء مع جميع الأنظمة المساعدة و أبراج التبريد نحن نبذل قصارى جهدنا لمنح أنظمة التدفئة والتهوية وتكييف الهواء الخاصة بك اقصى عمر لها بأكثر الطرق فعالية .',
      },
      {
        title: 'خدمات المولدات',
        desc: 'لدينا عقود من الخبرة في هذا المجال و تقدم بنجاح خدمات الصيانة وإصلاح المولدات من المؤسسات الصغيرة الحجم إلى المؤسسات واسعة النطاق في جميع القطاعات ذات الصلة في الكويت.',
      },
      {
        title: 'خدمات التنظيف',
        desc: 'بصفتنا شركة تنظيف محترفة ، نقوم بتدريب واعتماد موظفينا على الطرق الصحيحة للتنظيف. لدينا جميع المعدات والأدوات المهنية لإنجاز عملك نحن نستخدم برنامج ادارة المهام الخاص بنا لتحديد الترددات الصحيحة لإنجاز مهمة التنظيف بشكل صحيح',
      },
      {
        title: 'خدمات السباكة',
        desc: 'لدينا فنيين ذوي خبرة يقدمون مجموعة واسعة من خدمات السباكة المتكاملة  للمنشآت الجديدة او المقامة أو خدمات استكشاف الأخطاء وإصلاحها نتأكد من صيانة إمدادات المياه وأنابيب الصرف الصحي الخاصة بك. نتعامل أيضًا مع أنظمة مكافحة الحرائق.',
      },
      {
        title: 'خدمات تكنولوجيا المعلومات',
        desc: ' نستخدم أحدث الإجراءات والتقنيات في تنفيذ أفضل الخدمات الأمنية في الكويت باستخدام تقنيات العرض المتقدمة وأنظمة التقارير القوية التي تساعدنا وعملائنا على أن نكون على علم بكل التفاصيل.',
      },
      {
        title: 'خدمات كهربائية',
        desc: 'نقدم مجموعة واسعة من الخدمات تشمل الهندسة الكهربائية والأسلاك والاختبار الأرضي والكهربائي لعملائنا التجاريين والصناعيين. نحن نقدم خدمات الخبراء لعملائنا لتلبية احتياجاتهم و متطلباتهم. نحن نضمن لعملائنا من خلال توفير خدمات صيانة كهربائية فعالة.',
      },
      {
        title: 'خدمات التشجير',
        desc: 'لدينا مهندسين متخصصين وذوي خبرة متخصصون في مجال تنسيق الحدائق الذين يزورون موقعك و يفحصون التربة والسطح والمياه والصرف الصحي لتقديم خدمات تنسيق الحدائق من خلال تلبية متطلباتك.',
      },
      {
        title: 'خدمات إدارة المرافق',
        desc: 'نحن نعمل مع أسلوب عمل المنشآت من خلال مساعدة العملاء  والمستخدمين النهائيين على فهم التأثير المحتمل لقراراتهم على توفير المساحة والخدمات والتكلفة ومخاطر الأعمال. نحن نضمن بيئة مؤسسية فعالة من حيث التكلفة لكي تعمل المنظمات.',
      },
    ],

    partners: {
      title: 'عملائنا',
      desc: 'فيما يلي قائمة عملائنا. نحن نحب العمل معهم وهدفنا هو جعلهم سعداء.',
    },

    statisticsData: [
      {
        counter: '04',
        entity: 'العملاء',
      },
      {
        counter: '02',
        entity: 'المشاريع',
      },
      {
        counter: '01',
        entity: 'المواقع',
      },
      {
        counter: '06',
        entity: 'أرباب العمل',
      },
    ],

    positions: {
      title: 'هل أنت لائق؟<br>تحقق من مراكزنا المفتوحة!',
      positionItems: [
        {
          name: 'محاسب الابن',
          desc: `لدينا شاغر لك! نحن نبحث عن محاسب رائع للعمل جنبًا إلى جنب مع فريقنا.`,
          link: '#',
        },
        {
          name: 'مطور الواجهة الأمامية',
          desc: `أنت في المكان المناسب إذن! هذه الوظيفة متاحة لك: نحن نبحث عن مطور واجهات أمامية رائع للعمل جنبًا إلى جنب مع فرق التصميم والتطوير لدينا.`,
          link: '#',
        },
        {
          name: 'محاسب الابن',
          desc: `لدينا شاغر لك! نحن نبحث عن محاسب رائع للعمل جنبًا إلى جنب مع فريقنا.`,
          link: '#',
        },
        {
          name: 'مطور الواجهة الأمامية',
          desc: `أنت في المكان المناسب إذن! هذه الوظيفة متاحة لك: نحن نبحث عن مطور واجهات أمامية رائع للعمل جنبًا إلى جنب مع فرق التصميم والتطوير لدينا.`,
          link: '#',
        },
      ],
      positionsNote: {
        title: 'لا شيء لك هنا؟',
        desc: [
          'عرض جميع الوظائف الشاغرة.',
          'يقول',
        ],
      },
      viewVaccancy: 'عرض الوظيفة الشاغرة',
    },

    contactUs: {
      title: 'او تواصل معنا',
      desc: 'سواء كان لديك سؤال حول الميزات أو التجارب أو الأسعار أو أي شيء آخر ، فإن فريقنا جاهز للإجابة على جميع أسئلتك',
      address: 'العنوان: برج حمود ، 80 ، مدينة الكويت ، الكويت3',
      phone: 'هاتف:',
      form: {
        name: 'اسمك',
        email: 'بريدك الالكتروني',
        subject: 'موضوع الرسالة',
        message: 'رسالتك',
        submit: 'يقدم',
      },
    },

    footer: {
      footerNav: {
        about: 'معلومات عنا',
        services: 'خدماتنا',
        clients: 'عملائنا',
        jobs: 'وظائف',
        contact: 'اتصل بنا',
      },
      list1: [
        {
          name: 'الدعم',
          link: '#',
        },
        {
          name: 'صحافة',
          link: '#',
        },
        {
          name: 'النشرة الإخبارية',
          link: '#',
        },
        {
          name: 'أصول العلامة التجارية',
          link: '#',
        },
      ],
      list2: [
        {
          name: 'سياسة ملفات الارتباط',
          link: '#',
        },
        {
          name: 'البنود و الظروف',
          link: '#',
        },
        {
          name: 'سياسة خاصة',
          link: '#',
        },
        {
          name: 'التعليمات',
          link: '#',
        },
      ],
      legal: 'واحد إدارة المرافق ©',
    },
  },
};
