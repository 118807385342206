<template>
  <ul class="social">
    <li
      v-for="(item, index) in social"
      :key="index"
      class="social-item"
    >
      <a
        :href="item.link"
        target="_blank"
        rel="nofollow noreferrer noopener"
      >
        <img
          :src="require(`@/assets/img/social/${item.name}.svg`)"
          :alt="item.name"
        >
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SocialMedia',

  data() {
    return {
      social: [
        {
          name: 'facebook',
          link: 'https://www.facebook.com/ONEFacilitiesManagementCompany/',
        },
        {
          name: 'instagram',
          link: 'https://www.instagram.com/onefmkuwait',
        },
        {
          name: 'twitter',
          link: 'https://twitter.com/facilitiesone',
        },
        {
          name: 'linkedin',
          link: 'https://kw.linkedin.com/company/one-facilities-management-kuwait',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.social
  margin: 0
  padding: 0
  list-style: none
  display: flex
  align-items: center
.social-item
  padding: 0 20px
</style>
