import { createApp } from 'vue';
import App from './App.vue';
import langs from './langs';

const app = createApp(App);
const lang = window.localStorage.lang || 'en';
app.config.globalProperties.$lang = lang;
app.config.globalProperties.$langs = langs[lang];

if (lang === 'ar') document.body.style.direction = 'rtl';

app.mount('#app');
