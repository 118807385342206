<template>
  <Preview
    v-if="showPreview"
    @ready="pageReady"
  />
  <PageHeader
    :blocks="blocks"
    :activeBlock="activeBlock"
    @scrollTo="scrollToBlock"
  />
  <div class="container">
    <BlockWrap>
      <Home
        :ref="blocks.home"
        @toAboutUs="scrollToBlock(blocks.about)"
      />
      <AboutUs
        :ref="blocks.about"
        @toServices="scrollToBlock(blocks.services)"
      />
      <CompanyServices :ref="blocks.services" />
      <OurPartners :ref="blocks.clients" :projects="apiData.projects"
        :partners="apiData.partners" />
      <ContactUs :ref="blocks.contact" />
      <PageFooter
        :blocks="blocks"
        @scrollTo="scrollToBlock"
      />

      <img
        class="one-bg"
        ref="oneBg"
        :src="require('@/assets/img/one_bg.svg')"
        alt="One"
      />
    </BlockWrap>
  </div>
</template>

<script>
import 'normalize.css';

import BlockWrap from '@/components/BlockWrap';
import Preview from '@/components/Preview';
import PageHeader from '@/components/PageHeader';
import Home from '@/components/home/Home';
import AboutUs from '@/components/AboutUs';
import CompanyServices from '@/components/services/CompanyServices';
import OurPartners from '@/components/clients/OurPartners';
import ContactUs from '@/components/contact/ContactUs';
import PageFooter from '@/components/footer/PageFooter';

export default {
  name: 'App',

  components: {
    BlockWrap,
    Preview,
    PageHeader,
    Home,
    AboutUs,
    CompanyServices,
    OurPartners,
    ContactUs,
    PageFooter,
  },

  data() {
    return {
      blocks: {
        home: 'home',
        about: 'aboutUs',
        services: 'ourServices',
        clients: 'ourPartners',
        jobs: 'jobs',
        contact: 'contactUs',
        login: 'login',
      },
      activeBlock: 'home',
      scheduledAnimationFrame: false,
      isPageReady: false,
      showPreview: !window.sessionStorage.preview,
      apiData: {
        job_opening: [],
        projects: [],
        partners: [],
      },
    };
  },

  mounted() {
    window.addEventListener('resize', this.onScrollOrResize);
    window.addEventListener('scroll', this.onScrollOrResize);

    if (!this.showPreview) this.pageReady();
    this.getData();
  },

  unmounted() {
    window.removeEventListener('resize', this.onScrollOrResize);
    window.removeEventListener('scroll', this.onScrollOrResize);
  },

  methods: {
    async getData() {
      // get api data for render

      let res = await fetch('https://micro-api-gk4sqqu5rq-nw.a.run.app/website_data', {
        method: 'GET'
      });
      res = await res.json();
      console.log(res);
      // assign values
      this.apiData = res.message;
      return res;
    },
    onScrollOrResize() {
      if (this.scheduledAnimationFrame || !this.isPageReady) return;

      this.scheduledAnimationFrame = true;

      requestAnimationFrame(this.findActiveBlock);
    },

    findActiveBlock() {
      let blockName = null;
      // const halfWindowHeight = window.innerHeight / 2;

      for (const block in this.blocks) {
        const el = this.$refs[this.blocks[block]]?.$el;

        if (!el) continue;

        const blockTopOffset = el.getBoundingClientRect()?.top;

        if (blockTopOffset <= 100) {
          blockName = this.blocks[block];
        }
      }

      this.activeBlock = blockName;
      this.$refs[blockName].active = true;

      this.operateOneBg();

      this.scheduledAnimationFrame = false;
    },

    operateOneBg() {
      const percent = this.getScrollPercent();
      const oneBg = this.$refs.oneBg;

      if (!oneBg) return;

      if (percent > 17 && percent < 82) {
        oneBg.classList.add('active');
        oneBg.style.top = ((20 - percent) * 1.9) + '%';
      } else {
        oneBg.classList.remove('active');
      }
    },

    scrollToBlock(name) {
      const el = this.$refs[name]?.$el;

      if (el) el.scrollIntoView({ behavior: 'smooth' });
    },

    getScrollPercent() {
      const h = document.documentElement;
      const b = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';

      return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    },

    pageReady() {
      window.sessionStorage.setItem('preview', 1);
      this.isPageReady = true;
      this.findActiveBlock();
    },
  },
};
</script>

<style lang="sass">
@import "~@/assets/style/font"
@import "~@/assets/style/common"

body
  background-color: #181818
  transition: background-color .5s
  &.hide
    background-color: #000
    overflow: hidden
    .container
      opacity: 0
    .header-wrap
      .nav
        opacity: 0
      .logo
        left: 50%
        margin: 0 0 0 -85px
    .ar-header
      .header-wrap
        .logo
          right: 50%
          margin: 0 -85px 0 0
*, ::after, ::before
  box-sizing: border-box

#app
  font-family: 'Stone Sans Regular', sans-serif
  font-size: 16px
  color: #fff
.container
  transition: opacity .5s
.one-bg
  position: fixed
  opacity: 0
  top: 0
  left: 50%
  z-index: -1
  margin-left: -850px
  transition: opacity .3s, top 0s linear
  &.active
    opacity: .5

@media screen and (max-width: 1800px)
  .one-bg
    left: 60%
@media screen and (max-width: 1500px)
  .one-bg
    left: 80%
@media screen and (max-width: 1279px)
  .one-bg
    display: none
</style>
