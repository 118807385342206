<template>
  <video
    v-if="showVideo"
    autoplay
    loop
    muted
    class="video"
    :class="{ 'ar-video': $lang === 'ar' }"
  >
    <source
      :src="require('@/assets/video/home.mp4')"
      type="video/mp4"
    >
  </video>
</template>

<script>
export default {
  name: 'HomeVideo',

  data() {
    return {
      showVideo: false,
    };
  },

  mounted() {
    this.showVideo = true;
  },
};
</script>

<style lang="sass" scoped>
.video
  height: 100%
  z-index: -1

.ar-video
  transform: scaleX(-1)
@media screen and (max-width: 800px)
  .video
    margin: 0 -200px 0 0
  .ar-video
    margin: 0 0 0 -200px
@media screen and (max-width: 500px)
  .video
    margin: 0 -250px 0 0
  .ar-video
    margin: 0 0 0 -250px
</style>
