<template>
  <div
    class="sandwich"
    :class="{ active: showMenu, 'ar-sandwich': $lang === 'ar' }"
  >
    <span />
    <span />
    <span />
  </div>
</template>

<script>
export default {
  name: 'Sandwich',

  props: {
    showMenu: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.sandwich
  position: absolute
  padding: 5px
  right: 0
  pointer-events: all
  cursor: pointer
  opacity: .8
  transition: opacity .25s
  width: 32px
  height: 30px
  top: 30px
  right: 30px
  display: none
  z-index: 999
  &.active
    opacity: 1
    span
      position: relative
    span:nth-child(1)
      top: 7px
      transform: rotate(45deg)
    span:nth-child(2)
      display: none
    span:nth-child(3)
      // margin-top: 3px
      transform: rotate(-45deg)
  span
    display: block
    width: 22px
    height: 1px
    background: #fff
    transition: transform .25s
    & + span
      margin-top: 7px

.ar-sandwich
  right: auto
  left: 30px

@media screen and (max-width: 900px)
  .sandwich
    display: block
</style>
