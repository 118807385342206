<template>
  <div
    class="wrap"
    :class="{ 'ar-wrap': $lang === 'ar' }"
  >
    <div class="video-wrap">
      <HomeInfo
        :active="active"
        @toAboutUs="$emit('toAboutUs')"
      />
      <HomeVideo />
    </div>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
import HomeInfo from './HomeInfo';

export default {
  name: 'Home',

  components: {
    HomeVideo,
    HomeInfo,
  },

  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.wrap
  display: flex
  height: 1000px
  position: relative
.video-wrap
  height: 100%
  overflow: hidden
  display: flex
  justify-content: right
  align-items: center
  margin: 0 550px 0 0

.ar-wrap
  .video-wrap
    margin: 0 0 0 550px
    justify-content: left

@media screen and (max-width: 1500px)
  .video-wrap
     margin: 0 350px 0 0
  .ar-wrap
    .video-wrap
      margin: 0 0 0 350px
@media screen and (max-width: 1279px)
  .video-wrap,
  .ar-wrap .video-wrap
    margin: 0
@media screen and (max-width: 900px)
  .wrap
    height: 800px
@media screen and (max-width: 600px)
  .wrap
    height: 700px
</style>
