<template>
  <div
    class="wrap"
    :class="{ active, 'ar-wrap': $lang === 'ar' }"
  >
    <div class="info-wrap">
      <div class="info">
        <h2>{{ $langs.contactUs.title }}</h2>
        <p class="contacts">
          <a target="_blank" href="https://www.google.com/maps/place/Homoud+Tower/@29.3657649,47.9636707,17z/data=!3m1!4b1!4m5!3m4!1s0x3fcf84de0f886d8d:0xbae6e426a271a320!8m2!3d29.3657721!4d47.9658469">{{ $langs.contactUs.address }}</a><br>
          {{ $langs.contactUs.phone }} <a dir="ltr" href="tel:+9651866336">+965 1866336</a>
        </p>
        <p class="desc">{{ $langs.contactUs.desc }} </p>
        <div class="mapouter">
          <div class="gmap_canvas">
          <iframe width="250" height="250" id="gmap_canvas"
            src="https://maps.google.com/maps?q=Humoud%20Tower&t=k&z=19&ie=UTF8&iwloc=&output=embed"
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
          </iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="form-wrap">
      <ContactForm class="form" />
    </div>
    <div class="bg" />
  </div>
</template>

<script>
import ContactForm from './ContactForm';

export default {
  name: 'ContactUs',

  components: {
    ContactForm,
  },

  data() {
    return {
      active: false,
    };
  },
};
</script>

<style>.mapouter{position:relative;text-align:center; width:250; height:250;}</style>
<style>.gmap_canvas {overflow:hidden;background:none!important;width:250; height:250;}</style>

<style lang="sass" scoped>
.wrap
  display: flex
  padding: 0 0 0 140px
  min-height: 1000px
  &.active
    .info,
    .form
      top: 0
      opacity: 1
.info-wrap,
.form-wrap
  display: flex
  align-items: center
  margin: 0 160px 0 0
.info,
.form
  opacity: 0
  top: 30px
  position: relative
.info
  width: 255px
  transition: opacity .6s, top .6s
.form
  width: 530px
  transition: opacity .8s, top .8s
.bg
  align-items: stretch
  flex-grow: 1
  background: url('../../assets/img/contact_bg.jpg')
  background-size: cover

.ar-wrap
  padding: 0 140px 0 0
  .info-wrap,
  .form-wrap
    margin: 0 0 0 160px
  .desc,
  .contacts
   font-size: 17px

@media screen and (max-width: 1550px)
  .info-wrap,
  .form-wrap
    margin: 0 60px 0 0
  .form
    width: 400px
  .ar-wrap
    .info-wrap,
    .form-wrap
      margin: 0 0 0 60px
@media screen and (max-width: 1279px)
  .wrap
    padding: 0 0 0 40px
  .ar-wrap
    padding: 0 40px 0 0
@media screen and (max-width: 1179px)
  .bg
    display: none
  .wrap
    justify-content: space-between
    padding: 130px 40px 70px
    min-height: 500px
  .info,
  .form
    width: 100%
  .info-wrap,
  .form-wrap
    width: 50%
  .form-wrap
    margin: 0
  .ar-wrap
    padding: 130px 40px 70px
    .form-wrap
      margin: 0
@media screen and (max-width: 600px)
  .wrap
    flex-direction: column
    padding: 130px 15px 70px
  .info-wrap
    width: 100%
    margin: 0 0 50px
  .form-wrap
    width: 100%
  .ar-wrap
    padding: 130px 15px 70px
    .info-wrap
      margin: 0 0 50px
</style>
