<template>
    <div
      class="service"
      :class="{ 'ar-service': $lang === 'ar' }"
    >
      <div class="icon-wrap">
        <img
          class="icon"
          :src="require(`@/assets/img/services/service_${index + 1}.svg`)"
          :alt="title"
        >
      </div>
      <div>
        <p class="title">{{ title }}</p>
        <p class="desc">{{ desc }}</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SericeItem',

  props: {
    title: {
      type: String,
      required: true,
    },

    desc: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.service
  display: flex
  padding: 20px 35px
.title
  margin: 0 0 14px
  font-family: 'Stone Sans Bold'
  text-transform: uppercase
  color: #C3996C
  font-weight: 600
  letter-spacing: 3px
.desc
  margin: 0
  line-height: 24px
.icon-wrap
  width: 60px
  text-align: center
  flex: 0 0 60px
  margin: 0 20px 0 0
.icon
  width: auto

.ar-service
  .icon-wrap
    margin: 0 0 0 20px
  .title,
  .desc
    font-size: 17px

@media screen and (max-width: 1700px)
  .service
    padding: 20px 15px
@media screen and (max-width: 767px)
  .service
    padding: 20px 0
</style>
