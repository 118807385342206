<template>
  <div class="services-wrap">
    <div
      class="services"
      :class="{ active }"
    >
      <SericeItem
        v-for="(item, index) in $langs.services"
        :key="index"
        :title="item.title"
        :desc="item.desc"
        :index="index"
        class="service"
      />
    </div>
  </div>
</template>

<script>
import SericeItem from './SericeItem';

export default {
  name: 'CompanyServices',

  components: {
    SericeItem,
  },

  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.services-wrap
  display: flex
  align-items: center
  min-height: 1000px
  padding-top: 130px
.services
  display: flex
  margin: 0 150px
  flex-wrap: wrap
  &.active
    .service
      opacity: 1
      top: 0
.service
  width: 33.33%
  transition: opacity .5s, top .5s
  opacity: 0
  position: relative
  top: 50px

@media screen and (max-width: 1700px)
  .services
    margin: 0 40px
@media screen and (max-width: 1000px)
  .service
    width: 50%
@media screen and (max-width: 767px)
  .service
    width: 100%
@media screen and (max-width: 600px)
  .services
    margin: 0 15px
</style>
