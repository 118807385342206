<template>
  <div
    class="wrap"
    :class="{ active, 'ar-wrap': $lang === 'ar' }"
  >
    <div class="partners-wrap">
      <div>
        <h2 class="title">{{ $langs.partners.title }}</h2>
        <p class="desc">{{ $langs.partners.desc }}</p>
        <PartnersGallery :partners="partners"/>
      </div>
    </div>
    <div class="video-wrap">
      <ClientsVideo />
      <Statistics :active="active" :projects="projects"/>
    </div>
  </div>
</template>

<script>
import Statistics from './Statistics';
import ClientsVideo from './ClientsVideo';
import PartnersGallery from './PartnersGallery';

export default {
  name: 'OurPartners',
  props: {
    projects: {
      required: true,
      type: Object,
    },
    partners: {
      required: true,
      type: Array,
    },
  },
  components: {
    Statistics,
    ClientsVideo,
    PartnersGallery,
  },

  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.wrap
  display: flex
  padding: 0 0 0 140px
  height: 1000px
  margin: 50px 0
.video-wrap
  height: 100%
  position: relative
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
.partners-wrap
  min-width: 400px
  margin: 0 120px 0 0
  display: flex
  align-items: center
.desc
  margin: 0 0 100px
  font-size: 16px

.ar-wrap
  padding: 0 140px 0 0
  .desc
    font-size: 19px
  .partners-wrap
    margin: 0 0 0 120px

@media screen and (max-width: 1279px)
  .wrap
    padding: 0 0 0 40px
  .ar-wrap
    padding: 0 40px 0 0
@media screen and (max-width: 1000px)
  .partners-wrap
    margin: 0 40px 0 0
    min-width: 300px
  .ar-wrap
    .partners-wrap
      margin: 0 0 0 40px
@media screen and (max-width: 767px)
  .wrap
    display: block
    padding: 130px 0 0
    height: auto
  .video-wrap
    height: 400px
    width: 100%
    justify-content: center
  .partners-wrap
    margin: 0
    padding: 0 40px
  .desc
    margin: 0 0 50px
  .partners-wrap > div
    flex-grow: 1
  .ar-wrap
    padding: 130px 0 0
    .partners-wrap
      margin: 0
@media screen and (max-width: 600px)
  .partners-wrap
    padding: 0 15px
</style>
